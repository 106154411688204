// @flow
import type { Saga } from 'redux-saga'
import { fork } from 'redux-saga/effects'

import watchImpersonationRequests from './watchImpersonationRequest'
import watchProfileIdentity from './watchProfileIdentity'

export function* allSagas(): Saga<*> {
  yield fork(watchProfileIdentity)
  yield fork(watchImpersonationRequests)
}

export default allSagas
