// @flow
import type { Saga } from 'redux-saga'
import { select, takeLatest, call } from 'redux-saga/effects'

import { IMPERSONATE_CUSTOMER } from 'routes/profile/profileWrapper/actions'
import { pageSelectors } from 'routes/profile/profileWrapper/selectors'
import { openNewWindowOldImpersonation } from 'utils/openNewWindowOldImpersonation'

function* fetchImpersonationLinkFlow({
  callbackUrl,
}): Generator<Object, void, string> {
  const customerId = yield select(pageSelectors.getId)
  yield call(
    openNewWindowOldImpersonation,
    `/et/profiles/${customerId}/impersonate?callbackUrl=${encodeURIComponent(callbackUrl)}`,
    'Could not login as customer. This often happens because the customer has never logged in to their account before. Returning to ET3...',
  )
}

export default function* watchImpersonationRequests(): Saga<*> {
  yield takeLatest(IMPERSONATE_CUSTOMER, fetchImpersonationLinkFlow)
}
