import { call } from 'redux-saga/effects'

import { getTextFlow } from 'utils/services'

const loadingStyles = {
  'align-items': 'center',
  display: 'flex',
  'font-family': 'sans-serif',
  'font-size': '5vh',
  height: '80vh',
  'justify-content': 'center',
  margin: '0',
  padding: '15vh',
  'text-align': 'center',
}

function* openNewWindowOldImpersonation(url, errorText) {
  const newWindow = window.open()
  newWindow.document.body.innerText = 'Loading...'
  newWindow.document.body.style.cssText = Object.keys(loadingStyles)
    .map((property) => `${property}:${loadingStyles[property]};`)
    .join('')
  try {
    const newWindowUrl = yield call(getTextFlow, url)
    newWindow.location = newWindowUrl
  } catch (error) {
    newWindow.document.body.innerText = errorText
    setTimeout(() => {
      newWindow.close()
    }, 5000)
  }
}
export { openNewWindowOldImpersonation }
